.TypeSelect {
    width: 400px;
    display: flex;
}

@media screen and (max-width: 500px) {
    .TypeSelect {
        width: 100%;
    }
}

.TypeSelect button {
    flex: 1;
    border: 2px solid #ccc;
    margin-left: 7px ;
    background: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 5px;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    outline: 0;
    transition: all .3s;
    cursor: pointer;
}

.TypeSelect button:first-child {
    border-radius: 50px;
}

.TypeSelect button:last-child {
    border-radius: 50px;
}

.TypeSelect button.active {
    background: #fff;
    color: #000000;
}
