.Shortcuts {
    position: absolute;
    width: 27px;
    height: 24px;
    right: 70px;
    top: 25px;
}

@media screen and (max-width: 768px) {
    .Shortcuts {
        display: none;
    }
}

.Shortcuts i {
    font-size: 24px;
    color: rgb(255, 255, 255);
    opacity: 1;
    transition: all .3s;
}

.Shortcuts:hover i {
    opacity: 1;
}

.Shortcuts:hover .Shortcuts-box {
    opacity: 1;
    visibility: visible;
}

.Shortcuts .Shortcuts-box {
    position: absolute;
    top: 34px;
    right: 0;
    width: 300px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .07);
    padding: 25px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s;
}

.Shortcuts .Shortcut {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    color: #666;
}

.Shortcuts .Shortcut div {
    white-space: nowrap;
}

.Shortcuts .Shortcut:first-child {
    padding-top: 0;
}

.Shortcuts .Shortcut:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.Shortcuts .Shortcut kbd {
    display: inline-block;
    font-family: monospace;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0,0,0,.1), inset 0 0 0 1px #fff;
    color: #333;
    font-size: 11px;
    margin: 0 .15em;
    padding: .25em .7em;
    white-space: nowrap;
}
