.TimeDisplay {
    position: relative;
}

.TimeDisplay svg {
    max-width: 300px;
}

.TimeDisplay circle {
    transition: stroke-dashoffset 1s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.TimeDisplay > div {
    position: absolute;
    left: 50%;
    top: 49%;
    transform: translate(-50%, -32px);
    text-align: center;
}

.TimeDisplay h1 {
    font-size: 52px;
    font-weight: 300;
    color: #ffffff;
    letter-spacing: 2px;
    margin: 0;
}

.TimeDisplay p {
    font-size: 14px;
    text-transform: uppercase;
    color: transparent;
    letter-spacing: 3px;
    margin: 10px 0 0;
}
